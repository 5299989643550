import Vuex from 'vuex';

import { createLocalVue, mount, shallowMount } from '@vue/test-utils';
import SelectPaymentMethod from './SelectPaymentMethod.vue';

import PaymentProviderComponents from './paymentMethodsProviders/PaymentProviderComponents';

import { nameSpace as PaymentMethodsHubNameSpace } from './stores/PaymentMethodsHub';

jest.mock('./paymentMethodsProviders/PaymentProviderComponents', () => ({
  providerUtils: () => jest.fn(),
  providerAddPaymentMethod: () => jest.fn(),
}));

const isBusiness = false;

const B2CCollection = [{
  holder: 'pep',
  number: '4444',
  type: 'visa',
  payment_method_type: 'card',
  uuid: 'A',
}];

const B2BCollection = [
  {
    type: 'bank_transfer',
    payment_method_type: 'bank_transfer',
    number: null,
    uuid: 'BT',
  },
  {
    type: 'iban',
    payment_method_type: 'sepa',
    number: 'ES1120200234567102533424',
    uuid: 'iban',
  },
];

const currentPaymentMethod = isBusiness
  ? B2BCollection[0]
  : B2CCollection[0];

const paymentMethodsStore = {
  namespaced: true,
  state: {
    paymentMethodsCollectionB2B: B2BCollection,
    paymentMethodsCollectionB2C: B2CCollection,
  },
  getters: {
    getDefaultPaymentMethodUUID: () => jest.fn().mockResolvedValue('A'),
    getPaymentMethodByUUID: () => jest.fn().mockResolvedValue(currentPaymentMethod),
    isEmptyPaymentMethodsCollection: () => jest.fn(),
  },
};

const mockedModules = {
  [PaymentMethodsHubNameSpace]: paymentMethodsStore,
};

const localVue = createLocalVue();
localVue.use(Vuex);

const store = new Vuex.Store({
  modules: mockedModules,
});

const callWrapper = {
  propsData: {
    userUuid: 'a',
    profile: {
      isBusinessInCompanyPays: false,
    },
    provider: 'AdyenTest/3.13.0',
    locale: 'es_ES',
    hideCvc: false,
    selectLocked: false,
  },
  data: () => ({
    currentPaymentMethod,
    currentPaymentMethodUUID: 'A',
  }),
  localVue,
  store,
};

let wrapper;
describe('Given SelectPaymentMethod component', () => {
  afterEach(() => {
    jest.resetModules();
  });
  describe('When implemented with "AdyenTest/3.13.0" as a provider', () => {
    it('Then should call "providerAddPaymentMethod" with "AdyenTest/3.13.0"', () => {
      const providerAddPaymentMethodSpy = jest.spyOn(PaymentProviderComponents, 'providerAddPaymentMethod').mockImplementation();

      callWrapper.propsData.profile.isBusinessInCompanyPays = false;

      wrapper = mount(SelectPaymentMethod, callWrapper);

      expect(providerAddPaymentMethodSpy).toHaveBeenCalledWith('AdyenTest/3.13.0');
    });
  });

  describe('When a payment method is selected', () => {
    describe('And the payment method selected is different than the previous one', () => {
      it('Then the payment method selected is emitted', async () => {
        currentPaymentMethod.uuid = 'B';

        wrapper = shallowMount(SelectPaymentMethod, callWrapper);

        await wrapper.vm.onSelectPaymentMethod();

        expect(wrapper.emitted('update:payment-method-selected')[0][0]).toBe('A');
      });
    });

    describe('And the payment method selected is not different than the previous one', () => {
      it('Then the payment method selected is not emitted', async () => {
        currentPaymentMethod.uuid = 'A';

        wrapper = shallowMount(SelectPaymentMethod, callWrapper);

        await wrapper.vm.onSelectPaymentMethod();

        expect(wrapper.emitted('update:payment-method-selected')).not.toBe('A');
      });
    });
  });

  describe('When the "onClickAddPaymentMethod" is called', () => {
    it('Then the "launch-add-payment-method" is emitted', async () => {
      wrapper = shallowMount(SelectPaymentMethod, callWrapper);

      await wrapper.vm.onClickAddPaymentMethod();

      expect(wrapper.emitted('launch-add-payment-method')).toBeTruthy();
    });
  });

  describe('When the "onCVCCandidatePaymentMethod" is called', () => {
    it('Then the "update:cvc-candidate-payment-method" is emitted', async () => {
      wrapper = shallowMount(SelectPaymentMethod, callWrapper);

      const cvc = {
        isValid: true,
      };

      await wrapper.vm.onCVCCandidatePaymentMethod(cvc);

      expect(wrapper.emitted('update:cvc-candidate-payment-method')[0][0]).toStrictEqual({ isValid: true });
    });
  });

  describe('When the "normalizedPaymentMethods" is called', () => {
    describe('And the isBusinessInCompanyPays is false', () => {
      it('Then must return the correct normalized methods', async () => {
        callWrapper.propsData.profile.isBusinessInCompanyPays = false;

        wrapper = mount(SelectPaymentMethod, callWrapper);

        const B2Cnormalized = await wrapper.vm.normalizedPaymentMethods;

        expect(B2Cnormalized[0].label).toStrictEqual('**** **** **** 4444');
      });
    });

    describe('And the isBusinessInCompanyPays is true', () => {
      it('Then must return the correct normalized methods', async () => {
        callWrapper.propsData.profile.isBusinessInCompanyPays = true;

        wrapper = mount(SelectPaymentMethod, callWrapper);

        const B2Bnormalized = await wrapper.vm.normalizedPaymentMethods;

        expect(B2Bnormalized[0].label).toStrictEqual('common.payment_method.bank_transfer');
        expect(B2Bnormalized[1].label).toStrictEqual('ES11****************3424');
      });
    });
  });
});
